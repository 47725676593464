
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: String,
            default: ''
        },
        editMode: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        showViewPdf: {
            type: Boolean,
            default: false
        },
        showShare: {
            type: Boolean,
            default: false
        },
        hideEdit: {
            type: Boolean,
            default: false
        },
        titleClass: {
            type: String,
            default: ''
        },
        form: {
            type: String,
            default: ''
        },
        hideActionsOnMobile: {
            type: Boolean,
            default: false
        }
    },
    async setup(props, { emit }) {
        function back() {
            emit('back');
        }
        function edit() {
            emit('edit');
        }
        function shareDocument() {
            emit('shareDocument');
        }
        function viewPdfDocument() {
            emit('viewPdfDocument');
        }
        return { back, edit, shareDocument, viewPdfDocument };
    }
});
