
import { defineComponent, ref, onMounted, inject, onUnmounted, computed } from 'vue';
import swal from 'sweetalert2';
import { BusinessAccountingFilter, BusinessClientAccount } from '@/models/BusinessClients';
import { api } from '@/services/Api';
import q from 'q';
import { DocumentType, InvoiceType } from '@/models/Enums';
import { generalStore } from '@/store';
import router from '@/router';
import CustomLoader from '@/components/CustomLoader.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'BusinessClientAccounting',
    props: {
        businessClientId: {
            type: Number,
            required: true
        },
        selectedPage: {
            type: Boolean,
            required: true
        }
    },
    components: { CustomLoader },
    async setup(props) {
        const { t } = useI18n();
        const loading = ref(false);
        const filter = ref(new BusinessAccountingFilter(props.businessClientId));
        const filterCopy: BusinessAccountingFilter = generalStore.getters.getBusinessAccountingFilter;
        if (filterCopy) {
            filter.value = filterCopy;
        }
        const accounts = ref<BusinessClientAccount[]>([]);
        const busy = ref(false);
        const hasNext = ref(false);
        const emitter: any = inject('emitter');
        const total = ref(0);
        const totalFiltered = ref(0);
        const isMobile = window.innerWidth < 1075;
        const perfomaInvoiceTitle = computed(() => generalStore.state.defaultProviderSettings?.defaultProviderSettingsPerfomaInvoiceTitle);
        const hasFilter = computed<boolean>(() => {
            if (filter.value.documentType) {
                return true;
            }
            if (filter.value.documentNumber) {
                return true;
            }
            if (filter.value.customer) {
                return true;
            }
            if (filter.value.orderNumber) {
                return true;
            }
            if (filter.value.createDateEnd) {
                return true;
            }
            if (filter.value.createDateStart) {
                return true;
            }
            if (filter.value.userId) {
                return true;
            }
            return false;
        });
        const getBusinessAccounts = async () => {
            busy.value = true;
            const filterData: BusinessAccountingFilter = JSON.parse(JSON.stringify(filter.value));
            const apiResultPromise = api.getBusinessAccounts(filterData);
            await q.delay(400);
            const apiResult = await apiResultPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return false;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (apiResult.data && filterData.pageIndex == 0) {
                total.value = apiResult.data.total;
                totalFiltered.value = apiResult.data.totalFiltered;
            }
            if (apiResult.data && accounts.value && accounts.value.length) {
                accounts.value = [...accounts.value, ...apiResult.data.rows];
            } else {
                accounts.value = apiResult.data?.rows || [];
            }
            filter.value.pageIndex++;
            busy.value = false;
            return true;
        };

        async function search() {
            swal.showLoading();
            accounts.value = [];
            filter.value.pageIndex = 0;
            const ok = await getBusinessAccounts();
            if (ok) {
                swal.close();
            }
        }

        async function showAll() {
            swal.showLoading();
            filter.value = new BusinessAccountingFilter(props.businessClientId);
            accounts.value = [];
            const ok = await getBusinessAccounts();
            if (ok) {
                swal.close();
            }
        }

        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            filter.value.pageIndex = 0;
            accounts.value = [];
            swal.showLoading();
            const ok = await getBusinessAccounts();
            if (ok) {
                swal.close();
            }
        }

        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }

        async function ListenergetBusinessAccounts() {
            if (hasNext.value && !busy.value && props.selectedPage) {
                await getBusinessAccounts();
            }
        }
        async function onMountedAction() {
            loading.value = true;
            filter.value.pageIndex = 0;
            await getBusinessAccounts();
            loading.value = false;
            emitter.on('isBottom', ListenergetBusinessAccounts);
        }

        function goFilter() {
            generalStore.commit('saveBusinessAccountingFilter', filter.value);
            router.push({ name: 'businessAccountingFilter', params: { clientId: props.businessClientId } });
        }
        async function shareDocument(doc: BusinessClientAccount) {
            router.push({ name: 'sharedocument', params: { documentId: doc.id } });
        }
        async function showPdf(doc: BusinessClientAccount) {
            if (!doc.pdfUrl) {
                if (doc.documentType == DocumentType.Receipt) {
                    swal.showLoading();
                    const apiPromise = api.getBusinessReceiptPdf(doc.id);
                    await q.delay(400);
                    const apiResult = await apiPromise;
                    if (apiResult.errorMessage) {
                        swal.fire({
                            icon: 'error',
                            text: apiResult.errorMessage
                        });
                        return;
                    }
                    if (!apiResult.data || apiResult.data.pdfUrl == null || apiResult.data.pdfUrlWithInvoices == null) {
                        swal.fire({
                            icon: 'error',
                            text: t('alert.pdfNotReady')
                        });
                        return;
                    }
                    doc.pdfUrl = apiResult.data.pdfUrl;
                    doc.pdfUrlWithInvoices = apiResult.data.pdfUrlWithInvoices;
                    swal.close();
                } else {
                    swal.showLoading();
                    const apiPromise = api.getBusinessInvoicePdf(doc.id);
                    await q.delay(400);
                    const apiResult = await apiPromise;
                    if (apiResult.errorMessage) {
                        swal.fire({
                            icon: 'error',
                            text: apiResult.errorMessage
                        });
                        return;
                    }
                    if (!apiResult.data || apiResult.data.pdfUrl == null) {
                        swal.fire({
                            icon: 'error',
                            text: t('alert.pdfNotReady')
                        });
                        return;
                    }
                    doc.pdfUrl = apiResult.data.pdfUrl;
                    swal.close();
                }
                await q.delay(200);
            }

            if (doc.documentType == DocumentType.Receipt) {
                const swalAction = await swal.fire({
                    icon: 'info',
                    text: t('alert.businessReceiptPdfMsg'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('button.yes'),
                    cancelButtonText: t('button.no')
                });
                if (swalAction.isConfirmed) {
                    window.open(doc.pdfUrlWithInvoices, '_blank');
                } else {
                    window.open(doc.pdfUrl, '_blank');
                }
            } else {
                window.open(doc.pdfUrl, '_blank');
            }
        }
        function createPerfomaInvoice() {
            router.push({ name: 'businessPerfomaInvoiceNew', params: { clientId: props.businessClientId } });
        }
        function createCreditInvoice() {
            router.push({ name: 'businessCreditInvoiceNew', params: { clientId: props.businessClientId } });
        }
        function createInvoice() {
            router.push({ name: 'businessInvoiceNew', params: { clientId: props.businessClientId } });
        }
        function createReceipt() {
            router.push({ name: 'businessReceiptNew', params: { clientId: props.businessClientId } });
        }

        function getDocTypeName(account: BusinessClientAccount) {
            if (account.documentType == DocumentType.Invoice && account.invoiceType) {
                switch (account.invoiceType) {
                    case InvoiceType.Perfoma:
                        return perfomaInvoiceTitle.value;
                    case InvoiceType.Credit:
                        return t('documentTypes.CreditInvoice');
                    case InvoiceType.Debit:
                        return t('documentTypes.Invoice');
                    default:
                        return t('documentTypes.' + InvoiceType[account.invoiceType]);
                }
            }

            return t('documentTypes.' + DocumentType[account.documentType]);
        }

        onMounted(onMountedAction);
        onUnmounted(() => emitter.off('isBottom', ListenergetBusinessAccounts));
        return {
            accounts,
            hasNext,
            getArrowClass,
            sotrBy,
            filter,
            totalFiltered,
            total,
            showAll,
            hasFilter,
            isMobile,
            search,
            DocumentType,
            InvoiceType,
            goFilter,
            loading,
            shareDocument,
            showPdf,
            createCreditInvoice,
            createPerfomaInvoice,
            createInvoice,
            createReceipt,
            getDocTypeName,
            perfomaInvoiceTitle
        };
    }
});
