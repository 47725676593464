
import { defineComponent, ref, onMounted, inject, onUnmounted, computed } from 'vue';
import swal from 'sweetalert2';
import { BusinessOrderProductsFilter, BusinessClientOrderProduct } from '@/models/BusinessClients';
import { api } from '@/services/Api';
import q from 'q';
import router from '@/router';
import { generalStore } from '@/store';
import CustomLoader from '@/components/CustomLoader.vue';

export default defineComponent({
    name: 'BusinessClientOrderProducts',
    props: {
        businessClientId: {
            type: Number,
            required: true
        },
        selectedPage: {
            type: Boolean,
            required: true
        }
    },
    components: { CustomLoader },
    async setup(props) {
        const loading = ref(false);
        const filter = ref(new BusinessOrderProductsFilter(props.businessClientId));
        const filterCopy: BusinessOrderProductsFilter = generalStore.getters.getBusinessOrderProductsFilter;
        if (filterCopy) {
            filter.value = filterCopy;
        }

        const orderProducts = ref<BusinessClientOrderProduct[]>([]);
        const busy = ref(false);
        const hasNext = ref(false);
        const emitter: any = inject('emitter');
        const total = ref(0);
        const totalFiltered = ref(0);
        const isMobile = window.innerWidth < 1075;
        const hasFilter = computed<boolean>(() => {
            if (filter.value.productId) {
                return true;
            }
            if (filter.value.orderNumber) {
                return true;
            }
            if (filter.value.couponId) {
                return true;
            }
            if (filter.value.customerName) {
                return true;
            }
            if (filter.value.createDateEnd) {
                return true;
            }
            if (filter.value.createDateStart) {
                return true;
            }
            return false;
        });
        const getBusinessOrderProducts = async () => {
            busy.value = true;
            const filterData: BusinessOrderProductsFilter = JSON.parse(JSON.stringify(filter.value));
            const apiResultPromise = api.getBusinessOrderProducts(filterData);
            await q.delay(400);
            const apiResult = await apiResultPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return false;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (apiResult.data && filterData.pageIndex == 0) {
                total.value = apiResult.data.total;
                totalFiltered.value = apiResult.data.totalFiltered;
            }
            if (apiResult.data && orderProducts.value && orderProducts.value.length) {
                orderProducts.value = [...orderProducts.value, ...apiResult.data.rows];
            } else {
                orderProducts.value = apiResult.data?.rows || [];
            }
            filter.value.pageIndex++;
            busy.value = false;
            return true;
        };

        async function search() {
            swal.showLoading();
            orderProducts.value = [];
            filter.value.pageIndex = 0;
            const ok = await getBusinessOrderProducts();
            if (ok) {
                swal.close();
            }
        }

        async function showAll() {
            swal.showLoading();
            filter.value = new BusinessOrderProductsFilter(props.businessClientId);
            orderProducts.value = [];
            const ok = await getBusinessOrderProducts();
            if (ok) {
                swal.close();
            }
        }

        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            filter.value.pageIndex = 0;
            orderProducts.value = [];
            swal.showLoading();
            const ok = await getBusinessOrderProducts();
            if (ok) {
                swal.close();
            }
        }

        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }

        function showFilter() {
            generalStore.commit('saveBusinessOrderProductsFilter', filter.value);
            router.push({ name: 'businessOrderProductsFilter', params: { clientId: props.businessClientId } });
        }

        async function ListenerGetBusinessOrderProducts() {
            if (hasNext.value && !busy.value && props.selectedPage) {
                await getBusinessOrderProducts();
            }
        }
        async function onMountedAction() {
            loading.value = true;
            filter.value.pageIndex = 0;
            await getBusinessOrderProducts();
            loading.value = false;
            emitter.on('isBottom', ListenerGetBusinessOrderProducts);
        }
        onMounted(onMountedAction);
        onUnmounted(() => emitter.off('isBottom', ListenerGetBusinessOrderProducts));
        return {
            orderProducts,
            hasNext,
            getArrowClass,
            sotrBy,
            filter,
            totalFiltered,
            total,
            showAll,
            hasFilter,
            isMobile,
            search,
            showFilter,
            loading
        };
    }
});
