
import { defineComponent } from 'vue';
import ProviderCoupons from '@/components/Coupons/ProviderCoupons.vue';

export default defineComponent({
    name: 'BusinessClientOrderProducts',
    props: {
        businessClientId: {
            type: Number,
            required: true
        }
    },
    components: {
        ProviderCoupons
    },
    async setup(props) {
        return {
        };
    }
});
